import React from "react";
import { Layout } from "antd";
import { AssetsTable } from "features/table";
import { Sidebar } from "features/sidebar";
import "antd/dist/antd.css";

export const AssetsPage = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout className="site-layout">
        <Sidebar />
        <AssetsTable />
      </Layout>
    </Layout>
  );
};
