import React, { useState } from "react";
import { Layout, Table, DatePicker, Tabs } from "antd";
import { Sidebar } from "features/sidebar";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import { createSimpleColumn } from "shared/helpers";

import moment from "moment";
import "./index.scss";

const getTopAssetsQuery = loader("graphql/getTopAssets.graphql");
const { RangePicker } = DatePicker;

export const AnalyticsPage = () => {
  const { TabPane } = Tabs;
  const [page, setPage] = useState("1");
  const [value, setValue] = useState(null);
  const onChange = (key) => setPage(key);

  const { data } = useQuery(getTopAssetsQuery, {
    variables: value?.length && { start: moment(value?.[0]).format("YYYY-MM-DD"), end: moment(value?.[1]).format("YYYY-MM-DD") },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });
  const assets = data?.getTopAssets.map((item, index) => ({ ...item, ...item.asset, time: item.wholeTime, preview: item.asset.coverLandscape }));
  const columns = ["preview", "title", "assetId", "views", "time"].map((item, index) => createSimpleColumn(item, index));

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <Layout style={{ minHeight: "100vh" }}>
        <div className="analytics-page-header">
          <Tabs defaultActiveKey="1" onChange={onChange}>
            <TabPane tab="Top Assets" key="1"></TabPane>
            <TabPane tab="Top Users" key="2"></TabPane>
          </Tabs>
          <RangePicker onChange={(val) => setValue(val)} />
        </div>
        {page === "1" ? (
          <Table className="top-table" columns={columns || []} dataSource={assets} pagination={false} rowKey="assetId" />
        ) : (
          <Table className="top-table" columns={columns || []} dataSource={assets} pagination={false} rowKey="assetId" />
        )}
      </Layout>
    </Layout>
  );
};
