/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:b9107c36-5ca5-4ea9-aeaa-3da891d6207b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_MXM7m6y2J",
    "aws_user_pools_web_client_id": "1ijvmvtv0qbm8ebajnefviitta",
    "oauth": {},
    "aws_cognito_login_mechanism": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    }
};


export default awsmobile;
