import axios from "axios";

const api = axios.create({
  baseURL: "https://e12okrgguj.execute-api.us-east-1.amazonaws.com",
  headers: {
    
  }
});

export class CognitoController {
  async createUser({ Username, Password, Email }) {
    return await axios.post("https://e12okrgguj.execute-api.us-east-1.amazonaws.com/createUser", { Username, Password, Email });
  }
  async deleteUser({ Username }) {
    return await api.post("/deleteUser", { Username });
  }
  async getUsersList() {
    return await api.get("/getUsersList");
  }
}
