/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useState } from "react";
import { Table, Form, Button, Modal, Input, notification, Layout } from "antd";
import "antd/dist/antd.css";
import "@ant-design/pro-table/dist/table.css";
import { useEffect } from "react";
import { CognitoController } from "shared/helpers/cognitoController";
import { capitalizeFirstLetter } from "shared/helpers";
import { useMemo } from "react";
import { Sidebar } from "features/sidebar";

const openNotificationWithIcon = (type, message) => {
  notification[type]({
    message: message,
  });
};

export const UsersPage = () => {
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const cognito = useMemo(() => new CognitoController(), []);

  const getUsers = useCallback(async () => {
    const { data } = await cognito.getUsersList();

    const usersList = data.Users?.map((user) => {
      let usr = {
        username: user.Username,
        enabled: user.Enabled,
        status: user.UserStatus,
      };

      user?.Attributes?.forEach(({ Name, Value }) => {
        usr[Name] = Value;
      });

      return usr;
    });

    setUsers(usersList);
  }, [cognito]);

  useEffect(() => {
    getUsers();
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createUser = async ({ Username, Password, Email }) => {
    try {
      await cognito.createUser({ Username, Password, Email });
      openNotificationWithIcon("success", "User created successfully");
      setIsModalVisible(false);
    } catch (error) {
      openNotificationWithIcon("error", "Failed to create user");
      console.error(error);
    }
  };

  const deleteUser = async ({ Username }) => {
    try {
      await cognito.deleteUser({ Username });
      openNotificationWithIcon("success", "User deleted successfully");
    } catch (error) {
      openNotificationWithIcon("error", "Failed to delete user");
      console.error(error);
    }
  };

  const columns = ["username", "email", "enabled", "status", "actions"]?.map(
    (item, index) => ({
      title: capitalizeFirstLetter(item),
      key: index.toString(),
      dataIndex: item,
      editable: item !== "preview",
      shouldCellUpdate: (prevRecord, nextRecord) => {
        return prevRecord.lastModified !== nextRecord.lastModified;
      },
      render: (text, record, index) => {
        if (item === "enabled") {
          return record ? "Enabled" : "Disabled";
        }
        if (item === "actions") {
          return (
            <>
              <Button
                type="danger"
                onClick={() => deleteUser({ Username: record.username })}
              >
                Delete
              </Button>
            </>
          );
        }

        return record[item] || "";
      },
    })
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <div className="main-page">
        <div className="main-page-header">
          <Button type="primary" onClick={showModal}>
            Add user
          </Button>
        </div>
        <Form form={form} component={false}>
          <Table
            columns={columns || []}
            dataSource={users}
            bordered
            rowClassName="editable-row"
            pagination={false}
            rowKey="guid"
          />
        </Form>
        <Modal
          title="Add Cognito Pool user"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            initialValues={{ remember: true }}
            onFinish={createUser}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Username"
              name="Username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="Password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Email"
              name="Email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Layout>
  );
};
