import { useState } from "react";
import axios from "axios";
import { Button, Progress } from "antd";
import { DropzoneDialog } from "material-ui-dropzone";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";
import { capitalizeFirstLetter } from "shared/helpers";

export const FileUploader = ({
  open,
  closeCallback,
  guid = null,
  bucket,
  filename,
  button = false,
  successCallback,
  record,
  text,
  icon
}) => {
  const [files, setFiles] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const upload = async (files) => {
    const filesWithProgress = {};
    files.forEach((file) => {
      filesWithProgress[file.path] = { ...file, progress: 0 };
    });
    setFiles(filesWithProgress);

    for (const file of Object.values(files)) {
      const fname =
        "main." + file.name.split(".")[file.name.split(".").length - 1];

      const objectKey = filename
        ? filename + "." + file.name.split(".")[file.name.split(".").length - 1]
        : guid
        ? `${guid}/${fname}`
        : file.name;

      const { data: url } = await axios.post(
        "https://8luq7nwg11.execute-api.us-east-1.amazonaws.com/dev/presigned",
        {
          objectKey,
          s3Action: "putObject",
          contentType: file.type,
          bucketName: bucket,
        }
      );

      uploadS3(
        url,
        file,
        progressCallback,
        filesWithProgress,
        objectKey,
        filename
      );
    }
  };

  const uploadS3 = (
    url,
    file,
    progressCallback,
    files,
    objectKey,
    filename
  ) => {
    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr);
          } else {
            reject(xhr);
          }
        }
      };

      if (progressCallback) {
        xhr.upload.onprogress = (e) => {
          if (e.lengthComputable) {
            const percentComplete = (e.loaded / file.size) * 100;
            progressCallback(percentComplete, file, files);
          }
        };

        xhr.upload.onloadend = () => {
          progressCallback(100, file, files);

          const fileType =
            successCallback &&
            filename.split("/")[filename.split("/").length - 1];

          successCallback &&
            successCallback(
              record,
              fileType
                .split("-")[0]
                .concat(capitalizeFirstLetter(fileType.split("-")[1])),
              `https://${bucket}.s3.amazonaws.com/` +
                objectKey +
                "?" +
                new Date().getTime()
            );
        };
      }

      xhr.open("PUT", url);
      xhr.send(file);
    });
  };

  const progressCallback = (percent, file, files) => {
    const newFiles = { ...files };
    files[file.name].progress = percent;
    setFiles(newFiles);
  };

  const useStyles = makeStyles((theme) =>
    createStyles({
      previewChip: {
        minWidth: 160,
        maxWidth: 210,
      },
    })
  );

  const classes = useStyles();

  return (
    <>
      <div>
        <DropzoneDialog
          cancelButtonText={"Cancel"}
          submitButtonText={"Submit"}
          open={button ? isOpen : open}
          maxFileSize={9999999999999999999999}
          onClose={() => (button ? setIsOpen(false) : closeCallback())}
          onSave={(files) => {
            upload(files);
            button ? setIsOpen(false) : closeCallback();
          }}
          showPreviews={true}
          showFileNamesInPreview={true}
          showPreviewsInDropzone={false}
          useChipsForPreview
          previewGridProps={{ container: { spacing: 1, direction: "column" } }}
          previewChipProps={{ classes: { root: classes.previewChip } }}
          previewText="Selected files"
        />
      </div>
      <div className="file-uploader-list">
        {Object.values(files).length >= 1 ? (
          Object.values(files).map((file) => (
            <>
              <div>{file.path}</div>
              <Progress percent={Math.round(file.progress)} />
            </>
          ))
        ) : Object.values(files).length < 1 && button ? (
          <Button className="uploader-button" onClick={() => setIsOpen(true)} type="primary">
            {text || icon || "Upload"}
          </Button>
        ) : null}
      </div>
    </>
  );
};
