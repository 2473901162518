import { Link } from "react-router-dom";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export const createContentTableColumn = (item, index, asset) => {
  return {
    title: item,
    key: index.toString(),
    dataIndex: item,
    editable: item !== "preview",
    shouldCellUpdate: (prevRecord, nextRecord) => {
      return prevRecord.lastModified !== nextRecord.lastModified;
    },
    render: (text, record) => {
      if (!record[item]) {
        return record;
      }
      if (item === "preview") {
        if (record.title !== "video") {
          const src = record.title;

          alert(src);

          return record[item] ? (
            <Zoom>
              <img
                alt="Not uploaded"
                key={new Date().toLocaleDateString()}
                id="preview"
                src={asset?.[src]}
                onerror="this.src='imagefound.gif';"
              />
            </Zoom>
          ) : (
            "Not uploaded"
          );
        } else {
          return asset?.hlsUrl ? asset?.hlsUrl : "Not uploaded";
        }
      }
      return item === "guid" ? (
        <Link to={`content/${record[item]}`}>{record[item]}</Link>
      ) : (
        record[item] || ""
      );
    },
  };
};
