/* eslint-disable jsx-a11y/img-redundant-alt */
import { Link } from "react-router-dom";
import { capitalizeFirstLetter, convertDuration } from ".";
import notFound from "../../assets/not-found.png";

export const createSimpleColumn = (item, index) => {
  const notEditableFields = ["content", "preview", "published"];
  return {
    title: capitalizeFirstLetter(item),
    key: index.toString(),
    dataIndex: item,
    editable: !notEditableFields.includes(item),
    sorter: item === "views" || item === "time" ? (a, b) => Number(a[item]) - Number(b[item]) : null,
    render: (text, record) => {
      if (item === "preview") {
        const { coverLandscape, coverPortrait, lowresPortrait } = record;
        const src = coverLandscape || coverPortrait || lowresPortrait || "";
        return <img alt="not exist" key={new Date().toLocaleDateString()} id="preview" src={src} onError={(e) => (e.target.src = notFound)} />;
      }
      if (item === "content") {
        return <Link to={`content/${record.guid}?title=${record.title}`}>Edit</Link>;
      }

      if (item === "published") {
        return !!text ? "Published" : "Not published";
      }

      if (item === "duration") {
        return convertDuration(text);
      }

      return String(text)?.includes("https:") ? (
        <a key={item} href={text}>
          {text}
        </a>
      ) : (
        record[item] || ""
      );
    },
  };
};
