import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import { AssetsPage } from "pages/assets";
import { ContentPage } from "pages/content";
import { withAuthenticator, SignIn, RequireNewPassword, VerifyContact, Greetings, ForgotPassword } from "aws-amplify-react";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

import "./index.scss";
import "@aws-amplify/ui/dist/style.css";

import Amplify from "@aws-amplify/core";
import { Auth } from "@aws-amplify/auth";
import awsconfig from "./aws-exports";
import { UsersPage } from "pages/users";
import { TaxonomyPage } from "pages/taxonomy";
import { AnalyticsPage } from "pages/analytics";
require("dotenv").config();
Amplify.configure(awsconfig);
Auth.configure(awsconfig);

const history = createBrowserHistory();

const client = new ApolloClient({
  uri: "https://hadujhzz4rgwbblj4adipsvgta.appsync-api.us-east-1.amazonaws.com/graphql",
  headers: {
    "x-api-key": "da2-fwq3oxltlvbo3anz36ew4cux64",
  },
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

const App = () => {
  return (
    <BrowserRouter history={history}>
      <ApolloProvider client={client}>
        <Switch>
          <Route path="/" exact>
            <AssetsPage />
          </Route>
          <Route path="/content/:guid" exact>
            <ContentPage />
          </Route>
          <Route path="/users" exact>
            <UsersPage />
          </Route>
          <Route path="/taxonomy" exact>
            <TaxonomyPage />
          </Route>
          <Route path="/analytics" exact>
            <AnalyticsPage />
          </Route>
        </Switch>
      </ApolloProvider>
    </BrowserRouter>
  );
};

export default withAuthenticator(App, true, [<SignIn />, <RequireNewPassword />, <VerifyContact />, <ForgotPassword />, <Greetings />]);
