import React from "react";
import { Layout, Menu } from "antd";
import { TableOutlined, UnorderedListOutlined, UserSwitchOutlined, BarChartOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const { Sider } = Layout;

export class Sidebar extends React.Component {
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  render() {
    const pathname = window.location.pathname;

    const tabs = {
      "/content": "1",
      "/users": "2",
      "/taxonomy": "3",
      "/analytics": "4"
    };

    return (
      <Sider collapsed>
        <div className="logo" />
        <Menu theme="dark" defaultSelectedKeys={[tabs[pathname]]} mode="inline">
          <Menu.Item key="1" icon={<TableOutlined />}>
            <Link to="/">Assets</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<UserSwitchOutlined />}>
            <Link to="/users">Users</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<UnorderedListOutlined />}>
            <Link to="/taxonomy">Taxonomy</Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<BarChartOutlined />}>
            <Link to="/analytics">Analytics</Link>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}
