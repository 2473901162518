import { CloudUploadOutlined, CloseOutlined } from "@material-ui/icons";
import { Input, Form, Tag, Image, Button, Select } from "antd";
import { FileUploader } from "features/file-uploader";
import { capitalizeFirstLetter } from "shared/helpers";
import notFound from "../../assets/not-found.png";

const { Option } = Select;

export const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  fields,
  successCallback,
  deleteImageCallback,
  ...restProps
}) => {
  const arrayValuesFiels = ["cast", "languages"];
  const fields_ = {
    genres: {
      items: fields?.genres?.items.map((i) => i.title) || [],
      type: "multiple",
    },
    categories: {
      items: fields?.categories?.items.map((i) => i.title) || [],
      type: "multiple",
    },
    series: {
      items: fields?.series?.items.map((i) => i.title) || [],
      type: "multiple",
    },
    tags: {
      items: fields?.tags?.items.map((i) => i.title) || [],
      type: "multiple",
    },
    languages: {
      items: fields?.languages?.items.map((i) => i.title) || [],
      type: "multiple",
    },
  };

  const isArray = dataIndex && record[dataIndex] && Array.isArray(record[dataIndex]);

  const inputNode =
    isArray || arrayValuesFiels.includes(dataIndex) || fields_[dataIndex] ? (
      <Select mode={fields_[dataIndex]?.type || "tags"} style={{ width: 120 }}>
        {fields_[dataIndex]?.items.map((field) => (
          <Option key={field} value={field}>
            {field}
          </Option>
        ))}
      </Select>
    ) : (
      <Input className="table-input" />
    );

  const showTags = Array.isArray(...children.filter(Boolean));

  const isImageCell = dataIndex?.includes("landscape") || dataIndex?.includes("portrait");

  const imageDataIndex = isImageCell && dataIndex.split("-")[0].concat(capitalizeFirstLetter(dataIndex.split("-")[1]));

  return (
    <td {...restProps}>
      {isImageCell && !record[dataIndex.split("-")[0].concat(capitalizeFirstLetter(dataIndex.split("-")[1]))] ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img alt="not exist" key={new Date().toLocaleDateString()} id={`preview ${dataIndex.split("-")[1]}`} src={notFound} />
          <FileUploader
            button
            filename={`${record.type}/${record.title}/${dataIndex}`}
            bucket="taxonomy-img"
            successCallback={successCallback}
            record={record}
            icon={<CloudUploadOutlined style={{ fontSize: "16px" }} />}
          />
        </div>
      ) : isImageCell && record[imageDataIndex] ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image className={`preview ${dataIndex.split("-")[1]}`} src={record[imageDataIndex]} />
          <Button type="danger" className="uploader-button" onClick={() => deleteImageCallback(record, imageDataIndex)}>
            <CloseOutlined style={{ fontSize: "16px" }} />
          </Button>
        </div>
      ) : editing && inputType ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
        >
          {inputNode}
        </Form.Item>
      ) : showTags ? (
        children.filter(Boolean)[0].map((child, index) => <Tag key={index}>{child}</Tag>)
      ) : (
        children
      )}
    </td>
  );
};
