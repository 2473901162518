/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useMemo, useState } from "react";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { loader } from "graphql.macro";
import { Table, Popconfirm, Form, Button, Dropdown, Menu } from "antd";
import { EditableCell } from "./editable-cell";
import "antd/dist/antd.css";
import "@ant-design/pro-table/dist/table.css";
import { createSimpleColumn } from "shared/helpers/createSimpleColumn";
import axios from "axios";
import { FileUploader } from "features/file-uploader";
import { Link } from "react-router-dom";
import { getRandomKey } from "shared/helpers";
import { v4 as uuidv4 } from "uuid";

const query = loader("graphql/assets.graphql");
const mutation = loader("graphql/update.graphql");
const taxonomies = loader("graphql/getTaxonomies.graphql");
const createMutation = loader("graphql/create.graphql");

const createAssetGenreMutation = loader("graphql/createAssetGenre.graphql");
const createAssetLanguageMutation = loader("graphql/createAssetLanguage.graphql");
const createAssetSerieMutation = loader("graphql/createAssetSerie.graphql");
const createAssetTagMutation = loader("graphql/createAssetTag.graphql");
const createAssetCategoryMutation = loader("graphql/createAssetCategory.graphql");

const deleteAssetGenreMutationMutation = loader("graphql/deleteAssetGenre.graphql");
const deleteAssetLanguageMutation = loader("graphql/deleteAssetLanguage.graphql");
const deleteAssetSerieMutation = loader("graphql/deleteAssetSerie.graphql");
const deleteAssetTagMutation = loader("graphql/deleteAssetTag.graphql");
const deleteAssetCategoryMutation = loader("graphql/deleteAssetCategory.graphql");

const updateAssetGenreMutationMutation = loader("graphql/updateAssetGenre.graphql");
const updateAssetLanguageMutation = loader("graphql/updateAssetLanguage.graphql");
const updateAssetSerieMutation = loader("graphql/updateAssetSerie.graphql");
const updateAssetTagMutation = loader("graphql/updateAssetTag.graphql");
const updateAssetCategoryMutation = loader("graphql/updateAssetCategory.graphql");
const updateTimestampMutation = loader("graphql/updateTimestamp.graphql");

const listAssetGenresQuery = loader("graphql/listAssetGenres.graphql");
const listAssetLanguagesQuery = loader("graphql/listAssetLanguages.graphql");
const listAssetSeriesQuery = loader("graphql/listAssetSeries.graphql");
const listAssetTagsQuery = loader("graphql/listAssetTags.graphql");
const listAssetCategoriesQuery = loader("graphql/listAssetCategories.graphql");

export const AssetsTable = () => {
  const client = useApolloClient();
  const [form] = Form.useForm();

  const { loading, data } = useQuery(query);

  const { data: taxonomiesFields } = useQuery(taxonomies);

  const [items, setItems] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [open, setOpen] = useState(false);

  const [updateAsset] = useMutation(mutation);
  const [createAsset] = useMutation(createMutation);

  const [createAssetGenre] = useMutation(createAssetGenreMutation);
  const [createAssetLanguage] = useMutation(createAssetLanguageMutation);
  const [createAssetSerie] = useMutation(createAssetSerieMutation);
  const [createAssetTag] = useMutation(createAssetTagMutation);
  const [createAssetCategory] = useMutation(createAssetCategoryMutation);

  const [deleteAssetGenre] = useMutation(deleteAssetGenreMutationMutation);
  const [deleteAssetLanguage] = useMutation(deleteAssetLanguageMutation);
  const [deleteAssetSerie] = useMutation(deleteAssetSerieMutation);
  const [deleteAssetTag] = useMutation(deleteAssetTagMutation);
  const [deleteAssetCategory] = useMutation(deleteAssetCategoryMutation);

  const [updateAssetGenre] = useMutation(updateAssetGenreMutationMutation);
  const [updateAssetLanguage] = useMutation(updateAssetLanguageMutation);
  const [updateAssetSerie] = useMutation(updateAssetSerieMutation);
  const [updateAssetTag] = useMutation(updateAssetTagMutation);
  const [updateAssetCategory] = useMutation(updateAssetCategoryMutation);
  const [updateTimestamp] = useMutation(updateTimestampMutation);

  const { data: assetGenres, refetch: refetchGenres } = useQuery(listAssetGenresQuery);
  const { data: assetLanguages, refetch: refetchLanguages } = useQuery(listAssetLanguagesQuery);
  const { data: assetSeries, refetch: refetchSeries } = useQuery(listAssetSeriesQuery);
  const { data: assetTags, refetch: refetchTags } = useQuery(listAssetTagsQuery);
  const { data: assetCategories, refetch: refetchCategories } = useQuery(listAssetCategoriesQuery);

  const map = {
    genres: {
      create: createAssetGenre,
      delete: deleteAssetGenre,
      update: updateAssetGenre,
    },
    tags: {
      create: createAssetTag,
      delete: deleteAssetTag,
      update: updateAssetTag,
    },
    languages: {
      create: createAssetLanguage,
      delete: deleteAssetLanguage,
      update: updateAssetLanguage,
    },
    series: {
      create: createAssetSerie,
      delete: deleteAssetSerie,
      update: updateAssetSerie,
    },
    categories: {
      create: createAssetCategory,
      delete: deleteAssetCategory,
      update: updateAssetCategory,
    },
    timestamps: {
      update: updateTimestamp,
    },
  };

  const rawItems = useMemo(
    () =>
      data?.listAssets.items.map((item) => ({
        ...item,
        genres: [...item.genres.items.map((el) => el.genre?.title)],
        languages: [...item.languages.items.map((el) => el.language?.title)],
        tags: [...item.tags.items].map((el) => el.tag?.title),
        series: [...item.series.items.map((el) => el.serie?.title)],
        categories: [...item.categories.items.map((el) => el.category?.title)],
      })) || [],
    [data]
  );

  const addAsset = async () => {
    const id = uuidv4();
    const { data } = await createAsset({ variables: { input: { id, guid: id, published: 0 } } });
    setItems((state) => {
      return [data.createAsset, ...state];
    });
  };

  useEffect(() => {
    if (loading) return;
    setItems(rawItems);

    return () => null;
  }, [loading, rawItems, client]);

  if (loading) return <>lodaing...</>;

  const isEditing = (record) => record.guid === editingKey;

  const rawColumns = [
    "preview",
    "cast",
    "blurb",
    "description",
    "director",
    "duration",
    "languages",
    "notes",
    "rights",
    "title",
    "writer",
    "year",
    "genres",
    "series",
    "categories",
    "tags",
  ].map((item, index) => createSimpleColumn(item, index));

  const columns = [
    ...rawColumns,
    {
      title: "actions",
      dataIndex: "actions",
      render: (_, record, index) => {
        const editable = isEditing(record);
        return editable ? (
          <Dropdown.Button
            overlay={
              <Menu>
                <Menu.Item key={getRandomKey()} type="primary" onClick={() => save(record)}>
                  Save
                </Menu.Item>
                <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                  <Menu.Item key={getRandomKey()} type="primary">
                    Cancel
                  </Menu.Item>
                </Popconfirm>
              </Menu>
            }
          />
        ) : (
          <Dropdown.Button
            type="primary"
            overlay={
              <Menu>
                <Menu.Item key={getRandomKey()} disabled={editingKey !== ""} type="success" onClick={() => edit(record)}>
                  Edit
                </Menu.Item>
                <Menu.Item key={getRandomKey()} type="success">
                  <Link to={`content/${record.guid}?title=${record.title}`}>Content</Link>
                </Menu.Item>

                <Menu.Item
                  key={getRandomKey()}
                  disabled={!(!!record.title && !!record.hlsUrl && !!record.guid)}
                  onClick={() => publishAsset(record)}
                  type="success"
                >
                  {record.published ? "Unpublish" : "Publish"}
                </Menu.Item>
                <Menu.Item key={getRandomKey()} disabled={editingKey !== ""} type="success">
                  <Popconfirm title="Sure to delete?" onConfirm={() => deleteAsset(record)}>
                    Delete
                  </Popconfirm>
                </Menu.Item>
              </Menu>
            }
          />
        );
      },
    },
  ];

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.guid);
  };
  const processingArrayFields = async (record, row) => {
    console.log(row.genres?.sort());
    /** genres */
    if (!record.genres || row.genres.length !== record.genres.length || !row.genres?.sort().equals(record.genres.sort())) {
      if (row.genres?.length > record.genres?.length) {
        const diff = row.genres?.filter((el) => !record.genres?.includes(el));
        for (const element of diff) {
          console.log(taxonomiesFields, element);
          const { id: genreID } = taxonomiesFields.genres.items?.find((el) => el.title === element);
          await map.genres.create({
            variables: { input: { assetID: record?.id, published: record.published, genreID } },
          });
          await refetchGenres();
        }
      } else {
        console.log("record", record);
        const dest = record.genres || [];
        console.log(dest);
        const diff = dest.filter((el) => !row.genres.includes(el));
        for (const element of diff) {
          console.log(taxonomiesFields, element);
          const { id: genreID } = taxonomiesFields.genres.items?.find((el) => el.title === element);
          const { id: assetGenreID } = assetGenres.listAssetGenres.items.find((el) => el.genreID === genreID && el.assetID === record.id);
          await map.genres.delete({
            variables: { input: { id: assetGenreID } },
          });
        }
      }
    }
    /** languages */
    if (!record.languages || row.languages.length !== record.languages.length || !row.languages?.sort().equals(record.languages.sort())) {
      if (row.languages?.length > record.languages?.length) {
        const diff = row.languages.filter((el) => !record.languages.includes(el));
        for (const element of diff) {
          const { id: languageID } = taxonomiesFields.languages.items.find((el) => el.title === element);
          await map.languages.create({
            variables: { input: { assetID: record?.id, published: record.published, languageID } },
          });
          await refetchLanguages();
        }
      } else {
        const dest = record.languages || [];
        const diff = dest.filter((el) => !row.languages.includes(el));
        for (const element of diff) {
          const { id: languageID } = taxonomiesFields.languages.items.find((el) => el.title === element);
          const { id: assetLanguageID } = assetLanguages.listAssetLanguages.items.find(
            (el) => el.languageID === languageID && el.assetID === record.id
          );
          await map.languages.delete({
            variables: { input: { id: assetLanguageID } },
          });
        }
      }
    }
    /** series */
    if (!record.series || row.series.length !== record.series.length || !row.series?.sort().equals(record.series.sort())) {
      if (row.series?.length > record.series?.length) {
        const diff = row.series.filter((el) => !record.series.includes(el));
        for (const element of diff) {
          const { id: serieID } = taxonomiesFields.series.items.find((el) => el.title === element);
          await map.series.create({
            variables: { input: { assetID: record?.id, published: record.published, serieID } },
          });
          await refetchSeries();
        }
      } else {
        const dest = record.series || [];
        const diff = dest.filter((el) => !row.series.includes(el));
        for (const element of diff) {
          const { id: serieID } = taxonomiesFields.series.items.find((el) => el.title === element);
          const { id: assetSerieID } = assetSeries.listAssetSeries?.items.find((el) => el.serieID === serieID && el.assetID === record.id);
          await map.series.delete({
            variables: { input: { id: assetSerieID } },
          });
        }
      }
    }
    /** tags */
    if (!record.tags || row.tags.length !== record.tags.length || !row.tags?.sort().equals(record.tags.sort())) {
      if (row.tags?.length > record.tags?.length) {
        const diff = row.tags.filter((el) => !record.tags.includes(el));
        for (const element of diff) {
          const { id: tagID } = taxonomiesFields.tags.items.find((el) => el.title === element);
          await map.tags.create({
            variables: { input: { assetID: record?.id, published: record.published, tagID } },
          });
          await refetchTags();
        }
      } else {
        const dest = record.tags || [];
        const diff = dest.filter((el) => !row.tags.includes(el));
        console.log("TAGS DIFF", diff);
        for (const element of diff) {
          const { id: tagID } = taxonomiesFields.tags.items.find((el) => el.title === element);
          const { id: assetTagID } = assetTags.listAssetTags.items?.find((el) => el.tagID === tagID && el.assetID === record.id);
          await map.tags.delete({
            variables: { input: { id: assetTagID } },
          });
        }
      }
    }
    /** categories */
    if (!record.categories || row.categories.length !== record.categories.length || !row.categories?.sort().equals(record.categories.sort())) {
      if (row.categories?.length > record.categories?.length) {
        const diff = row.categories?.filter((el) => !record.categories.includes(el));
        for (const element of diff) {
          const { id: categoryID } = taxonomiesFields.categories?.items.find((el) => el.title === element);
          await map.categories.create({
            variables: { input: { assetID: record?.id, published: record.published, categoryID } },
          });
          await refetchCategories();
        }
      } else {
        const dest = record.categories || [];
        const diff = dest.filter((el) => !row.categories.includes(el));
        for (const element of diff) {
          const { id: categoryID } = taxonomiesFields.categories.items.find((el) => el.title === element);
          console.log(assetCategories, categoryID, record.id);
          const { id: assetTagID } = assetCategories.listAssetCategories?.items.find((el) => el.categoryID === categoryID && el.assetID === record.id);
          await map.categories.delete({
            variables: { input: { id: assetTagID } },
          });
        }
      }
    }
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      const newItems = [...items];
      const index = newItems.findIndex((item) => record.guid === item.guid);

      row.id = record.guid;
      delete row.guid;

      const validRow = Object.fromEntries(Object.entries(row).filter(([_, v]) => v != null));

      await processingArrayFields(record, row);

      delete validRow.languages;
      delete validRow.genres;
      delete validRow.series;
      delete validRow.tags;
      delete validRow.categories;

      validRow.id = record?.id || record.guid;

      await updateAsset({ variables: { input: validRow } });

      if (index > -1) {
        const item = newItems[index];
        newItems.splice(index, 1, { ...item, ...row });
        setItems(newItems);
        setEditingKey("");
      } else {
        newItems.push(row);
        setItems(newItems);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const cancel = () => {
    setEditingKey("");
  };

  const deleteAsset = async (record) => {
    await axios.post("https://8luq7nwg11.execute-api.us-east-1.amazonaws.com/dev/delete-asset", { guid: record.guid || record?.id });

    const newItems = [...items].filter((item) => item.guid !== record.guid);
    setItems(newItems);
  };

  const publishAsset = async (record, value) => {
    try {
      console.log(record);
      const groupFields = ["categories", "genres", "series", "tags", "languages", "timestamps"];
      const { guid, published = 0 } = record;

      await updateAsset({ variables: { input: { id: guid, published: value ? value : published ? 0 : 1 } } });
      const row = data?.listAssets.items.find((el) => el.guid === guid);
      console.log(row);

      for (let i = 0; i < groupFields.length; i++) {
        const group = groupFields[i];
        for (let j = 0; j < row[group]?.items.length; j++) {
          const element = row[group]?.items[j];
          console.log(element);
          await map[group].update({ variables: { input: { id: element?.id, published: value ? value : published ? 0 : 1 } } });
        }
      }

      const index = items.findIndex((item) => guid === item.guid);
      setItems((prev) => {
        let newItems = [...prev];
        newItems[index] = { ...newItems[index], published: value ? value : published ? 0 : 1 };
        return newItems;
      });
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        fields: taxonomiesFields,
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  // const testfn = async () => {
  //   console.log(test);
  //   for (let i = 0; i < test.length; i++) {
  //     const element = test[i];
  //     delete element.languages;
  //     delete element.genre;
  //     delete element.category;
  //     delete element.series;
  //     delete element.tags;
  //     if (!element?.id) return;
  //     await createAsset({ variables: { input: { ...element, published: 0 } } });
  //   }
  // };

  // const syncAssets = async () => {
  //   for (const asset of items) {
  //     console.log(asset.published);
  //     await publishAsset(asset, asset.published);
  //   }
  // };
  return (
    <div className="main-page">
      <FileUploader open={open} closeCallback={() => setOpen(false)} />
      <div className="main-page-header">
        <Button type="primary" onClick={addAsset}>
          Add asset
        </Button>
      </div>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={mergedColumns || []}
          dataSource={[...items].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()) || []}
          bordered
          rowClassName={(record) => {
            return `editable-row ${!!record.published ? "published-row" : "not-published-row"} `;
          }}
          pagination={false}
          rowKey="guid"
        />
      </Form>
    </div>
  );
};
