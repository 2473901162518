import React, { useState, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button, Dropdown, Form, Layout, Menu, Popconfirm, Table } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { Sidebar } from "features/sidebar";
import { createSimpleColumn } from "shared/helpers/createSimpleColumn";
import { EditableCell } from "features/table/editable-cell";
import { useEffect } from "react";

import "./index.scss";
import "antd/dist/antd.css";
import "react-medium-image-zoom/dist/styles.css";

import axios from "axios";
import { getRandomKey } from "shared/helpers";

const getTaxonomiesQuery = loader("graphql/getTaxonomies.graphql");

const deleteTagMutation = loader("graphql/deleteTag.graphql");
const deleteGenreMutation = loader("graphql/deleteGenre.graphql");
const deleteCategoryMutation = loader("graphql/deleteCategory.graphql");
const deleteSerieMutation = loader("graphql/deleteSerie.graphql");
const deleteLanguageMutation = loader("graphql/deleteLanguage.graphql");

const deleteAssetEntitiesMutation = loader("graphql/deleteAssetEntities.graphql");

const updateTagMutation = loader("graphql/updateTag.graphql");
const updateGenreMutation = loader("graphql/updateGenre.graphql");
const updateCategoryMutation = loader("graphql/updateCategory.graphql");
const updateSerieMutation = loader("graphql/updateSerie.graphql");
const updateLanguageMutation = loader("graphql/updateLanguage.graphql");
const updateTimestampMutation = loader("graphql/updateTimestamp.graphql");

const createTagMutation = loader("graphql/createTag.graphql");
const createGenreMutation = loader("graphql/createGenre.graphql");
const createCategoryMutation = loader("graphql/createCategory.graphql");
const createSerieMutation = loader("graphql/createSerie.graphql");
const createLanguageMutation = loader("graphql/createLanguage.graphql");

const headerColumns = {
  genres: ["title", "description", "cover-portrait", "cover-landscape", "lowres-landscape", "lowres-portrait"],
  categories: ["title", "description", "cover-portrait", "cover-landscape", "lowres-landscape", "lowres-portrait"],
  series: ["title", "description", "cover-portrait", "cover-landscape", "lowres-landscape", "lowres-portrait"],
  tags: ["title", "description"],
  languages: ["title", "description"],
};

export const TaxonomyPage = () => {
  const { data, refetch } = useQuery(getTaxonomiesQuery, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState(null);

  const [deleteTag] = useMutation(deleteTagMutation);
  const [deleteGenre] = useMutation(deleteGenreMutation);
  const [deleteCategory] = useMutation(deleteCategoryMutation);
  const [deleteSerie] = useMutation(deleteSerieMutation);
  const [deleteLanguage] = useMutation(deleteLanguageMutation);

  const [deleteAssetEntities] = useMutation(deleteAssetEntitiesMutation);

  const [updateTag] = useMutation(updateTagMutation);
  const [updateGenre] = useMutation(updateGenreMutation);
  const [updateCategory] = useMutation(updateCategoryMutation);
  const [updateSerie] = useMutation(updateSerieMutation);
  const [updateLanguage] = useMutation(updateLanguageMutation);
  const [updateTimestamp] = useMutation(updateTimestampMutation);

  const [createTag] = useMutation(createTagMutation);
  const [createGenre] = useMutation(createGenreMutation);
  const [createCategory] = useMutation(createCategoryMutation);
  const [createSerie] = useMutation(createSerieMutation);
  const [createLanguage] = useMutation(createLanguageMutation);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const map = {
    categories: {
      create: createCategory,
      update: updateCategory,
      delete: deleteCategory,
    },
    genres: {
      create: createGenre,
      update: updateGenre,
      delete: deleteGenre,
    },
    tags: {
      create: createTag,
      update: updateTag,
      delete: deleteTag,
    },
    languages: {
      create: createLanguage,
      update: updateLanguage,
      delete: deleteLanguage,
    },
    series: {
      create: createSerie,
      update: updateSerie,
      delete: deleteSerie,
    },
    timestamps: {
      update: updateTimestamp,
    },
  };

  const [items, setItems] = useState(data || {});

  const addRow = async (key) => {
    const { data } = await map[key].create({
      variables: { input: { title: "", id: uuidv4(), type: key } },
    });
    const item = Object.values(data)[0];
    setItems({
      ...items,
      [key]: {
        items: [item, ...items[key].items],
      },
    });
  };

  const editRow = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setIsEdit(record?.id);
  };

  const deleteImage = async (record, imageDataIndex) => {
    await axios.post("https://8luq7nwg11.execute-api.us-east-1.amazonaws.com/dev/delete-asset-image", {
      guid: `${record.type}/${record.title}/`,
      imageType: imageDataIndex,
      bucket: "taxonomy-img",
    });
    await map[record.type].update({
      variables: { input: { id: record?.id, [imageDataIndex]: null } },
    });
    await refetch();
  };

  const saveRow = async (record) => {
    try {
      const row = await form.validateFields();
      row.id = record?.id;

      const newItems = { ...items };
      const index = newItems?.[record?.type]?.items?.findIndex((item) => item?.id === record?.id);

      const validRow = Object.fromEntries(Object.entries(row).filter(([_, v]) => v != null));

      map[record.type]?.update({ variables: { input: validRow } });
      if (index > -1) {
        const item = newItems[record.type].items[index];
        const newRow = { ...item, ...row };
        const _newItems = [...newItems[record.type].items];
        _newItems[index] = newRow;
        const data = {
          ...newItems,
          [record.type]: {
            items: [..._newItems],
          },
        };
        setItems(data);
        setIsEdit("   ");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const deleteRow = async (record) => {
    try {
      await map[record.type].delete({ variables: { input: { id: record?.id } } });
      await deleteAssetEntities({ variables: { input: { ID: record?.id, entity: record.type } } });
      const newItems = { ...items, [record.type]: { items: items[record.type].items.filter((item) => item?.id !== record?.id) } };
      setItems(newItems);
      setIsEdit("   ");
    } catch (error) {
      console.log("Delete row failed:", error);
    }
  };

  useEffect(() => {
    setItems(data);
    return () => null;
  }, [data]);

  const uploadImageCallback = useCallback(
    async (record, field, path) => {
      const _record = { ...record };
      delete _record.createdAt;
      delete _record.updatedAt;
      await map[_record?.type].update({
        variables: { input: { ..._record, [field]: path } },
      });
      refetch();
    },
    [map, refetch]
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <Layout className="site-layout">
        {items &&
          Object.entries(items)?.map(([key, value], index) => {
            const columns = headerColumns[key]?.map((item, index) => createSimpleColumn(item, index));
            const content = [...value.items].sort((a, b) => {
              const dateA = new Date(a.createdAt),
                dateB = new Date(b.createdAt);
              return dateB - dateA;
            });
            console.log(content);
            const isEditing = (record) => {
              return record?.id === isEdit;
            };

            columns.push({
              title: "actions",
              dataIndex: "actions",
              width: 200,
              editable: false,
              render: (_, record, index) => {
                return (
                  <Dropdown.Button
                    type="primary"
                    overlay={
                      <Menu>
                        <Menu.Item key={getRandomKey()}>
                          {isEditing(record) ? (
                            <Button type="primary" onClick={() => saveRow(record)}>
                              Save
                            </Button>
                          ) : (
                            <Button type="primary" onClick={() => editRow(record)}>
                              Edit
                            </Button>
                          )}
                        </Menu.Item>
                        <Menu.Item key={getRandomKey()} disabled={isEditing(record)} type="success">
                          <Popconfirm title="Sure to delete?" onConfirm={() => deleteRow(record)}>
                            <Button type="danger">Delete</Button>
                          </Popconfirm>
                        </Menu.Item>
                      </Menu>
                    }
                  />
                );
              },
            });

            const mergedColumns = columns.map((col) => {
              if (!col.editable) {
                return col;
              }

              return {
                ...col,
                width: col.dataIndex === "actions" ? 100 : 300,
                onCell: (record) => ({
                  fields: data,
                  record,
                  inputType: "text",
                  dataIndex: col.dataIndex,
                  title: col.title,
                  successCallback: uploadImageCallback,
                  deleteImageCallback: deleteImage,
                  editing: isEditing(record),
                }),
              };
            });

            return (
              <div key={index + new Date().getTime()}>
                <Form form={form} component={false}>
                  <div className="taxonomy-header">
                    <div className="taxonomy-title">{key.charAt(0).toUpperCase() + key.slice(1)}</div>
                    <Button className="taxonomy-button" type="primary" onClick={() => addRow(key)}>
                      Add row
                    </Button>
                  </div>
                  <Table
                    columns={mergedColumns || []}
                    dataSource={content}
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    bordered
                    pagination={false}
                    rowClassName="editable-row"
                    className="taxonomy-table"
                    rowKey="id"
                  />
                </Form>
              </div>
            );
          })}
      </Layout>
    </Layout>
  );
};
